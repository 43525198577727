<template>
	<div class="index ftfont">
		<Header></Header>
		<router-view />
		<Footer></Footer>
	</div>
</template>
<script>
	import Header from './head/index.vue'
	import Footer from './foot/index.vue'
	export default {
		name: 'home',
		components: {
			Header,
			Footer
		},

		data() {
			return {
				homeProItemCurrent: -1,
				zxlyForm: {
					qymc: ""
				},
				
			
			}
		}

	}
</script>
<style>

</style>
